import apiService from '../../services/api'

export default {
	namespaced: true,
	state: {
		personalDetails: {
			firstName: null,
			lastName: null,
			email: null,
			contactNumber: null,
		},
		billingDetails: {
			address1: null,
			address2: null,
			suburb: null,
			city: null,
			province: null,
			postalCode: null,
		}
	},
	getters: {
		personalDetails: state => state.personalDetails,
		billingDetails: state => state.billingDetails,
	},
	mutations: {
		updatePersonalDetails: (state, value) => {
			state.personalDetails = value
		},
		updateBillingDetails: (state, value) => {
			state.billingDetails = value
		},
	},
	actions: {

	}
}
