import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import general from './modules/general'
import products from './modules/products'
import cart from './modules/cart'
import personalInfo from './modules/personalInfo'
import user from './modules/user'
import orders from './modules/orders'

Vue.use(Vuex);

const localStorage = new VuexPersist({
	storage: window.localStorage,
	key: 'walkin-cable-store',
	reducer: (state) => ({
		cart: state.cart,
		personalInfo: state.personalInfo,
	}),
})
const tokenStorage = new VuexPersist({
	storage: window.localStorage,
	key: 'ahWsUser',
	reducer: (state) => ({
		user: state.user
	}),
})

export default new Vuex.Store({
	modules: {
		general,
		products,
		cart,
		personalInfo,
		user,
		orders,
	},
	strict: true,
	plugins: [localStorage.plugin, tokenStorage.plugin]
})
