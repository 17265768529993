<template>
	<div class="quantity-input">
		<icon class="control" :class="{disabled: amount === 1}" name="control-point-subtract" @click.native.stop.prevent="updateValue(amount - 1)"></icon>
		<span class="value">{{amount}}</span>
		<icon class="control" name="control-point-plus" @click.native.stop.prevent="updateValue(amount + 1)"></icon>
	</div>
</template>
<script>
import Icon from "./Icon";
export default {
	components: {Icon},
	props: ['value'],
	data() {
		return {
			amount: 0
		}
	},
	methods: {
		updateValue(val) {
			if (val <= 0) {
				return;
			}
			this.amount = val;
			this.$emit('input', val);
		}
	},
	mounted() {
		if (this.value) {
			this.amount = this.value
		}
	}
}
</script>
<style lang="scss">
.quantity-input {
	display: inline-flex;
	padding: 1.3rem 2.2rem;
	justify-content: space-between;
	align-items: center;
	width: 14rem;
	font-weight: var(--font-weight-bold);
	background-color: var(--color-grey-light);
	border-radius: 100rem;

	& > * {
		&::selection {
			color: none;
			background: none;
		}
		&::-moz-selection {
			color: none;
			background: none;
		}
	}

	span {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	.icon {
		width: 2.4rem;
		height: 2.4rem;
		fill: var(--color-blue-medium);

		&.disabled {
			cursor: not-allowed;
			fill: var(--color-grey-medium);
		}
	}

	.control {
		cursor: pointer;
	}
}
</style>
