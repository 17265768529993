<template>
	<div class="icon">
		<svg><use :href="`#${name}`"></use></svg>
	</div> <!-- end.icon -->
</template>

<script>
export default {
	props: ['name']
}
</script>
<style lang="scss">

.icon {
	display: inline-flex;
	justify-content: center;
	align-content: center;
}
</style>
