<template>
	<div class="product" v-if="currentVariant">
		<div class="product-image">
			<img :src="`/assets/images/products/${currentVariant.sku}.png`" alt="product"/>
		</div>
		<div class="product-configurator"
			 :class="[
				{red: product.name.indexOf('Micro USB') > -1},
				{blue: product.name.indexOf('USB-C') > -1},
				{silver: product.name.indexOf('Lightning') > -1},
			 ]">
			<div class="product-colour">
				<span class="label">Color</span>
				<div class="colours">
					<div class="colour" v-for="variant in colours" :class="[variant.variant_value, {selected: currentVariantId === variant.id}]" @click.prevent="selectColor(variant.id)"></div>
				</div>
			</div>
			<div class="product-quantity">
				<span class="label">Quantity</span>
				<FormQuantity v-model="quantity"/>
			</div>
			<div class="product-price">
				<span class="label">R{{ currentVariant.price * quantity | formatPrice }}</span>
				<CTA @click="addToCart" :class="{added: addedToCart}">{{ addedToCart ? 'Added to Cart' : 'Add to Cart' }}</CTA>
			</div>
		</div>
		<div class="product-info">
			<div class="features" v-if="product.features">
				<h3>Features</h3>
				<ul>
					<li v-for="feature in JSON.parse(product.features)">
						{{ feature }}
					</li>
				</ul>
			</div>
			<div class="specs" v-if="product.specifications">
				<h3>Specifications</h3>
				<ul>
					<li v-for="specification in JSON.parse(product.specifications)">
						{{ specification }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import FormQuantity from "@/components/FormQuantity";
import CTA from "@/components/CTA";

export default {
	components: {CTA, FormQuantity},
	computed: {
		currentVariant() {
			return this.product ? this.product.variants.find(v => {
				return this.currentVariantId === v.id;
			}) : false;
		},
		colours() {
			return this.product ? this.product.variants.filter(v => v.variant_type === 'colour') : false
		},
		product() {
			return this.products ? this.products.find(p => this.$route.params.id === p.id.toString()) : false;
		},
		...mapGetters({
			products: 'products/products',
		}),
	},
	watch: {
		product(val) {
			this.currentVariantId = val ? val.variants[0].id : 0;
			this.$store.commit('general/updateNavHeader', this.product.name);
		}
	},
	data() {
		return {
			currentVariantId: 0,
			quantity: 1,
			addedToCart: false,
		}
	},
	methods: {
		selectColor(id) {
			this.currentVariantId = id;
		},
		addToCart() {
			this.$store.commit('cart/addCartItem', {
				...this.product,
				id: this.product.id,
				variant_id: this.currentVariantId,
				quantity: this.quantity,
			});
			this.addedToCart = true;
			setTimeout(()=> {
				this.addedToCart = false;
			}, 1500);
		}
	},
	mounted() {
		if (!this.products || this.products.length === 0) {
			this.$store.dispatch('products/getProducts');
		} else {
			this.$store.commit('general/updateNavHeader', this.product.name);
			this.currentVariantId = this.product.variants[0].id;
		}

	}
}
</script>
<style lang="scss">
.product {
	padding: 2rem;
	.product-image {
		display: flex;
		margin-bottom: 2rem;
		justify-content: center;

		img {
			align-self: center;
			border-bottom: 1px solid var(--color-grey-light);
			max-height: 100%;
			max-width: 22rem;
		}
	}

	.product-configurator {
		background: #FFFFFF;
		box-shadow: 0 10px 40px 0 rgba(190,197,210,0.30);
		border-radius: 14px;
		overflow: hidden;

		&::before {
			content: '';
			display: block;
			width: 100%;
			border-top: 0.5rem solid;
		}
		&.red::before {
			border-top-color: var(--color-package-red);
		}
		&.blue::before {
			border-top-color: var(--color-package-blue);
		}
		&.silver::before {
			border-top-color: var(--color-package-silver);
		}

		& > * {
			padding: 2rem;
			border-bottom: 0.1rem solid var(--color-grey-light);
			display: grid;
			grid-template-columns: 8rem 1fr;
			align-items: center;

			&:last-child {
				border-bottom: none;
			}
			.label {
				font-weight: var(--font-weight-bold);
				color: var(--color-grey-dark)
			}
		}

		.product-colour {
			.colours {
				display: flex;
				align-items: center;
				.colour {
					position: relative;
					width: 1.2rem;
					height: 1.2rem;
					border-radius: 100%;
					margin: 0 2.5rem 0 0;
					cursor: pointer;
					box-shadow: 0 0 5px 0 rgba(190,197,210);

					&.selected::before {
						content: '';
						position: absolute;
						top: -0.5rem;
						left: -0.5rem;
						bottom: -0.5rem;
						right: -0.5rem;
						border: 0.24rem solid var(--color-blue-light);
						border-radius: 100%;
					}
					&.white {
						background-color: white;
					}
					&.black {
						background-color: black;
					}
					&.silver {
						background-color: silver;
					}
				}
			}
		}

		.button {
			transition: 0.2s all ease-in-out;

			&.added {
				background-color: var(--color-green);
			}
		}
	}

	h3 {
		font-weight: var(--font-weight-black);
		font-size: 1.6rem;
		line-height: 2.4rem;
		margin: 2rem 0 1rem;
	}

	ul {
		padding-left: 1.5rem;
		li {
			list-style: disc;
			font-size: 1.4rem;
			line-height: 2.7rem;
			color: #989999;
		}
	}
}

</style>
