<template>
	<div class="section" id="orders">
		<div class="container">
			<div class="header">
				<h1>Order History</h1>
				<div class="filters">
					<div class="search">
						<input type="text" placeholder="Search..." v-model="searchTerm" @keyup="typingQuery" @keydown.enter="submitSearch">
						<icon v-if="!searchTerm" name="magnifier"/>
						<icon v-else name="cross" @click.stop.native="clearSearch"/>
					</div>
				</div>
			</div>
			<div class="overflow-container" :class="{loading: loading}">
				<div class="table-container">
					<table class="orders-table" :class="{orderOpen: currentOrder !== false}">
						<thead>
							<tr>
								<th class="order-number">Order Number</th>
								<th>Client Name</th>
								<th>Email Address</th>
								<th>Status</th>
								<th>Date</th>
								<th>Total Cost</th>
								<th colspan="2">Cart Total</th>
							</tr>
						</thead>
						<tbody v-if="searchOrders.length === 0 && searchTerm.length > 3 && !loading">
							<tr colspan="7">
								<td>No results found.</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr v-for="order in filteredOrders" :key="order.id" @click="show(order)" :class="{active: currentOrder.id === order.id}">
								<td class="order-number">#{{ order.id }}</td>
								<td class="client-name">{{ order.billing_details.first_name + ' ' + order.billing_details.last_name }}</td>
								<td class="client-email">{{ order.billing_details.email }}</td>
								<td class="status"><span class="indicator" :class="[$options.filters.formatStatus(order.status).toLowerCase()]"></span><span class="status-name">{{ order.status | formatStatus | titleCase}}</span></td>
								<td>{{ order.updated_at | formatDate }}</td>
								<td>R{{ order.total.toFixed(2).toLocaleString('en-ZA') }}</td>
								<td class="total">{{ order.total / 100 }}</td>
								<td class="arrow">
									<icon name="arrow-left" v-if="currentOrder.id === order.id" />
									<icon name="arrow-right" v-else />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="current-order" v-if="currentOrder">
					<div class="order-card">
						<div class="head">
							<span>Order<span class="order-number">#{{ currentOrder.id }}</span></span>
							<icon @click.native="closeOrder" name="cross"></icon>
						</div>
						<div class="body">
							<div class="personal-details">
								<div class="name">{{ `${currentOrder.billing_details.first_name} ${currentOrder.billing_details.last_name}` }}</div>
								<div class="email">{{currentOrder.billing_details.email }}</div>
								<div class="address">{{
									`${currentOrder.billing_address.address_1},
									${currentOrder.billing_address.address_2 ? currentOrder.billing_address.address_2 + ',' : ''}
									${currentOrder.billing_address.suburb},
									${currentOrder.billing_address.city},
									${currentOrder.billing_address.province},
									${currentOrder.billing_address.postal_code},
									`
									}}</div>
							</div>
<!--						<div class="payment-details">-->
<!--							<span class="bank">{{currentOrder.billing_details.bank }}</span>-->
<!--						</div>-->
							<div class="cart-items">
								<div class="item" v-for="item in currentOrder.items">
<!--									<div class="flex-row" v-for="index in item.quantity">-->
									<div class="flex-row">
										<div class="details">
<!--											<div class="name">{{ item.product.name }}</div>-->
											<div class="name">{{item.product.name}}&nbsp;{{ item.quantity > 1 ? `x${item.quantity}` : '' }}</div>
											<div class="colour">
												<div class="package"
													 :class="[
														{red: item.product.name.indexOf('Micro USB') > -1},
														{blue: item.product.name.indexOf('USB-C') > -1},
														{silver: item.product.name.indexOf('Lightning') > -1},
													]"></div>
											</div>
											<div class="desc">{{ item.product.description }}</div>
											<div class="price">R{{ item.productVariant.price | formatPrice }}</div>
										</div>
										<div class="image">
											<img :src="`/assets/images/products/${item.productVariant.sku}.png`" alt="product"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="foot">
							<span>Total</span>
							<span class="total">R{{ currentOrder.total.toFixed(2).toLocaleString('en-ZA') }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="pagination" v-if="orders.page">
				<div @click="fetchPage(orders.page - 1)">
					<icon name="arrow-left" :class="{disabled: orders.page === 1}"></icon>
				</div>
				<div class="pages">
					<span @click="fetchPage(page)" class="page" v-for="page in numPages" :key="page" :class="{active: page === orders.page}">{{ page }}</span>
				</div>
				<div @click="fetchPage(orders.page + 1)">
					<icon name="arrow-right" :class="{disabled: orders.page === orders.lastPage}"></icon>
				</div>
			</div>
		</div>
		<Loader/>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import Icon from "../../components/Icon";
import Loader from "../../components/Loader";
import moment from 'moment';

export default {
	components: {Loader, Icon},
	filters: {
		titleCase(value) {
			return (value[0]).toUpperCase() + (value.slice(1)).toLowerCase();
		},
		formatDate(value) {
			return moment(value).format('YYYY-MM-DD');
		},
		formatStatus(status) {
			switch (status) {
				case 'PAYMENT_CANCELLED':
					return 'Cancelled'
				default:
					return status
			}
		}
	},
	computed: {
		orders() {
			return this.searchOrders.length > 0 ? this.searchOrders.length : this.$store.getters['orders/orders']
		},
		filteredOrders() {
			return this.searchOrders.length > 0 ? this.searchOrders : this.orders.data;
		},
		numPages() {
			if (!this.orders) return [];

			let pages = [];
			for (let i = 0; i < this.orders.lastPage; i++) {
				pages[i] = i+1;
			}

			if (pages.length > 5) {
				if (this.orders.page > (this.orders.lastPage - 3)) {
					pages = pages.slice(this.orders.lastPage - 5, this.orders.lastPage);
				} else if (this.orders.page < 4) {
					pages = pages.slice(0, 5);
				} else {
					pages = pages.slice(this.orders.page - 3, this.orders.page + 2);
				}
			}

			return pages;
		},
	},
	watch: {
		searchTerm(value) {
			if (value.length === 0) {
				this.clearSearch()
			}
		}
	},
	methods: {
		async show(order) {
			// let that = this;
			// let loadDebounce = setTimeout(() => {
			// }, 500);
			this.loading = true;
			this.currentOrder = await this.$store.dispatch('orders/fetchOrder', order.id);
			this.loading = false;
		},
		closeOrder() {
			this.currentOrder = false;
		},
		async fetchPage(page) {
			this.loading = true;
			this.closeOrder();
			await this.$store.dispatch('orders/fetchOrders', '?page=' + page );
			this.loading = false;
		},
		submitSearch() {
			console.log('searching for: ' + this.searchTerm)

			this.loading = true;
			if (this.searchTerm.length > 3) {
				this.$store.dispatch('orders/searchOrders', {
					query: this.searchTerm
				})
					.then((res) => {
						this.searchOrders = res;
						this.loading = false;
					})
					.catch((error) => {
						console.error(error)
						this.loading = false;
					})
			} else {
				this.loading = false;
			}
		},
		clearSearch() {
			window.clearTimeout(this.searchDebounce);
			this.loading = false;
			this.searchTerm = "";
			this.searchOrders = [];
			this.currentOrder = false;
			this.closeOrder()
		},
		typingQuery(e) {
			let that = this;
			this.$nextTick(() => {
				console.log(that.searchTerm)
				//is searchTerm more than 3 characters?
				if (that.searchTerm.length > 3) {
					//is key pressed a number or letter
					let char = String.fromCharCode(e.keyCode); // Get the character
					if (/^[A-Za-z]+$/.test(char)) {
						that.loading = true;
						window.clearTimeout(that.searchDebounce);
						that.searchDebounce = window.setTimeout(() => {
							that.submitSearch()
						}, 1500);
					}
				}
			})

		}
	},
	data() {
		return {
			currentOrder: false,
			loading: true,
			searchTerm: "",
			searchOrders: [],
			searchDebounce: false
		}
	},
	async mounted() {
		if (!this.$store.getters['user/token']) {
			this.$router.push('/login')
			return
		}

		this.$store.commit('general/setLoading', true);
		this.loading = false;

		this.$store.dispatch('orders/fetchOrders')
		.then(async (res) => {
			this.$store.commit('general/setLoading', false);
		});
	}
}
</script>
<style lang="scss">
#orders {
	padding-top: 12rem;
	padding-left: 4rem;
	padding-right: 4rem;
	padding-bottom: 4rem;

	.container {
		padding: 0;
		max-width: 130rem;
		margin: 0 auto;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4rem;

		.filters {
			display: flex;
			min-width: 30rem;
			.search {
				position: relative;
				display: flex;
				width: 100%;
				font-size: 1rem;

				input {
					font-weight: var(--font-weight-bold);
					width: 100%;
					padding: 1.4rem 2rem;
					background-color: transparent;
					border: 0.1rem solid var(--color-grey-light);
					border-radius: 10rem;
				}

				.icon {
					cursor: pointer;
					position: absolute;
					right: 2rem;
					top: 50%;
					transform: translateY(-50%);
					height: 2.4rem;
					width: 2.4rem;
					padding: 0.35rem;
					fill: var(--color-grey-medium-2);

					svg {
					}
				}
			}

		}
	}

	.table-container {
		position: relative;
		max-width: 100%;
		//overflow: hidden;
		overflow-x: scroll;
	}

	.overflow-container {
		position: relative;
		display: flex;
		min-height: calc(100vh - 30rem);

		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(#fafafa, 0.8);
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 2.4rem;
			font-weight: var(--font-weight-bold);
			color: var(--color-grey-dark);
			pointer-events: none;
			opacity: 0;
			transition: 0.4s opacity ease-in-out;
		}

		&.loading {
			&::after {
				content: "Loading...";
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	.table-container {
		width: 100%;
	}

	.orders-table {
		text-align: left;
		width: 100%;

		$border-radius: 1.4rem;
		tr:first-child th:first-child {
			border-top-left-radius: $border-radius;
		}
		tr:first-child th:last-child {
			border-top-right-radius: $border-radius;
		}
		tr:last-child td:first-child {
			border-bottom-left-radius: $border-radius;
		}
		tr:last-child td:last-child {
			border-bottom-right-radius: $border-radius;
		}

		&.orderOpen {
			.status {
				text-align: center;

				.indicator {
					margin-right: 0;
				}
			}
			.status-name {
				display: none;
			}
		}

		td, th {
			vertical-align: middle;
			padding: 1.9rem 2rem 1.8rem;
			height: 6rem;

			white-space: nowrap;

			.icon {
				max-height: 1em;
				fill: var(--color-grey-medium-2);
			}

			&.arrow {
				width: 6.2rem;
				min-width: 6.2rem;
				cursor: pointer;
			}

			&:not(:last-child) {
				border-right: 0.1rem solid var(--color-grey-light);
			}

			.indicator {
				display: inline-block;
				border-radius: 10rem;
				width: 1rem;
				height: 1rem;
				margin-right: 1rem;
				background-color: var(--color-grey-light);

				&.pending {
					background-color: var(--color-orange);
				}
				&.paid {
					background-color: var(--color-green);
				}
				&.failed, &.cancelled {
					background-color: var(--color-red);
				}
			}

			&.status {
				white-space: nowrap;
			}

			&.total {
				//width: 12rem;
				max-width: 5rem;
				text-align: center;
			}
			&.client-name {
				max-width: 17rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.client-email {
				max-width: 17rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.order-number {
				max-width: 11rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		thead {
			background-color: var(--color-grey-light);
		}

		tbody {
			tr {
				background-color: #FFFFFF;
				cursor: pointer;
				//border: 0.1rem solid transparent;

				&:nth-child(even) {
					background-color: var(--color-grey-light-half);
				}

				&.active {
					position: relative;
					//border: 0.1rem solid #28BBF3;
					z-index: 2;
					outline: 0.1rem solid #28BBF3;

					.icon {
						fill: var(--color-blue-light);
					}
				}
			}
		}
	}

	.current-order {
		position: relative;
		max-width: 23%;
		min-width: 30rem;
		margin-left: 2rem;
		border-radius: 1.4rem;
		overflow: hidden;
		align-self: flex-start;

		.order-card {
			position: -webkit-sticky;
			position: sticky;
			top: 0;

			.head, .foot {
				display: flex;
				justify-content: space-between;
				align-items: center;

				padding: 1.8rem 2rem;
				background-color: var(--color-grey-light);
				font-weight: var(--font-weight-bold);
				font-size: 1.6rem;

				.order-number, .total {
					color: var(--color-blue-light);
					margin-left: 2rem;
				}

				.icon {
					width: 2.4rem;
					height: 2.4rem;
					padding: 0.4rem;
					cursor: pointer;
					fill: var(--color-grey-medium-2);
				}
			}
		}

		.body {
			background-color: #FFFFFF;

			& > * {
				padding: 2rem;
			}
			.personal-details {
				font-size: 1.2rem;
				line-height: 2.2rem;

				.name {
					font-weight: bold;
					font-size: 1.4rem;
					line-height: 2.4rem;
				}
				.address {
					margin-top: 0.5rem
				}
			}

			.cart-items {
				padding: 0;
				max-height: calc(100vh - 50rem);
				overflow-y: scroll;

				.item {
					padding: 2rem;
					border-bottom: 0.1rem solid var(--color-grey-light);

					&:last-child {
						border-bottom: none;
					}
				}

				.details {
					width: 50%;
					.name {
						font-size: 1.4rem;
						line-height: 2.4rem;
						font-weight: var(--font-weight-bold);
					}
					.colour {
						& > * {
							display: inline-block;
							height: 0.8rem;
							width: 100%;
							border-radius: 10rem;

							&.blue {
								background-color: var(--color-package-blue);
							}
							&.red {
								background-color: var(--color-package-red);
							}
							&.silver {
								background-color: var(--color-package-silver);
							}
						}
					}
					.desc {
						font-size: 1.4rem;
						line-height: 2.4rem;
					}
					.price {
						font-weight: var(--font-weight-bold);
						color: var(--color-blue-light);
						margin-top: 1rem;
					}
				}
				.image, .image img {
					width: 113px;
				}
			}

			& > *:not(:last-child) {
				border-bottom: 0.1rem solid var(--color-grey-light);
			}
		}

		.foot {
			border-radius: 0 0 1.4rem 1.4rem;
		}
	}

	.pagination {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		max-width: 33rem;
		margin: 0 auto;
		margin-top: 3rem;

		.pages {
			display: flex;
			justify-content: center;
			align-items: center;
			.page {
				cursor: pointer;
				user-select: none;
				width: 5rem;
				height: 3rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 10rem;
				font-weight: var(--font-weight-bold);

				&.active {
					background-color: var(--color-blue-light);
				}
			}
		}

		.icon {
			cursor: pointer;
			height: 2.4rem;
			width: 2.4rem;
			padding: 0.35rem;
			fill: var(--color-grey-medium-2);

			&.disabled {
				pointer-events: none;
				fill: var(--color-grey-dark);
				opacity: 0.1;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.header {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			h1 {
				margin-bottom: 2rem;
			}
		}
	}
}
</style>
