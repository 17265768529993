
const camelToUnderscore = (key) =>  {
	return key.replace( /([A-Z0-9])/g, "_$1").toLowerCase();
}
export default {
	camelObjToUnderscore: (object) => {
		let newObject = {};

		for(var camel in object) {
			newObject[camelToUnderscore(camel)] = object[camel];
		}

		return newObject
	}
}
