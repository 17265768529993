<template>
	<div class="footer flex-space-between align-center">
		<span>Subtotal ({{ totalItems }}<span class="tablet-portrait tablet-landscape desktop"> items</span>): R{{ totalPrice | formatPrice }}</span>
		<CTA @click="click">{{ ctaLabel }}</CTA>
	</div>
</template>
<script>
import CTA from "./CTA";
export default {
	components: {CTA},
	props: ['cta-label'],
	computed: {
		totalItems() {
			let amount = 0;
			this.$store.getters['cart/cartItems'].forEach(c => {
				amount += c.quantity;
			})
			return amount;
		},
		totalPrice() {
			let amount = 0;
			this.$store.getters['cart/cartItems'].forEach(c => {
				amount += c.quantity * c.variants.find(v => v.id === c.variant_id).price;
			})
			return amount;
		},
	},
	methods: {
		click(attr) {
			this.$emit('click', attr)
		}
	}
}
</script>
<style lang="scss">
.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-top: 0.1rem solid var(--color-grey-medium);
	padding: 2rem;
	background-color: #FFFFFF;
	font-weight: var(--font-weight-black);
}
</style>
