<template>
	<div class="form-input" :class="{'has-value': value && value.length, 'focused': focused}">
		<input
			:name="name"
			:value="value"
			:type="filteredType"
			:pattern="pattern"
			:title="title"
			:autocomplete="autocomplete"
			:maxlength="maxlength"
			:required="required"
			ref="input"
			@input="update()"
			@focus="focusInput"
			@blur="focused = false"
			:disabled="disabled">
		<span class="label-text" :class="{'required': required}">{{ label }}</span>
		<span class="form-input-toggle" @click.prevent="toggleVisibility()" v-if="type === 'password'"><icon :name="visible ? 'eye-hide' : 'eye-show'" /></span>
	</div><!-- end.form-input -->
</template>

<script>
	import Icon from "@/components/Icon";

	export default {
		components: {
			Icon,
		},
		props: [
			'disabled',
			'label',
			'required',
			'value',
			'type',
			'autocomplete',
			'maxlength',
			'focus',
			'name',
			'pattern',
			'title',
		],
		computed: {
			filteredType() {
				switch (this.type) {
					case 'password':
						return this.visible ? 'text' : 'password';
					default:
						return this.type;
				}
			},
		},
		methods: {
			update() {
				this.$emit('input', this.$refs.input.value)
			},
			toggleVisibility() {
				this.visible = !this.visible;
			},
			focusInput(e) {
				this.focused = true;
				this.$emit('focus', e);
			}
		},
		data() {
			return {
				focused: false,
				visible: false
			}
		},
	}
</script>

<style lang="scss">

	.form-input {
		position: relative;
		display: block;

		input {
			display: block;
			width: 100%;
			height: 6rem;
			border: .1rem solid var(--color-grey-medium);
			border-radius: 1rem;
			padding: 1rem 2rem 0;
			font-size: 1.2rem;
			color: var(--color-grey-dark);
			appearance: none;
			transition: all .2s ease;
		}

		.label-text {
			display: block;
			white-space: nowrap;
			font-size: 1.1rem;
			font-weight: var(--font-weight-medium);
			color: var(--color-grey-dark);
			position: absolute;
			top: 2.4rem;
			left: 2rem;
			opacity: 0.5;
			pointer-events: none;
			transition: all .2s ease;

			&::after {
				content: ' (optional)';
			}

			&.required::after {
				content: ' *';
			}
		}

		.form-input-toggle {
			display: block;
			width: 2.2rem;
			height: 2rem;
			position: absolute;
			right: 2rem;
			top: calc(50% - 1rem);
			cursor: pointer;
			user-select: none;

			.icon {
				height: 100%;
			}

			svg {
				fill: var(--color-grey-medium);
			}
		}

		&.has-value,
		&.focused {
			.label-text {
				top: 1.4rem;
				transform: none;
			}
			input:invalid {
				border-color: var(--color-red);
				background-color: rgba(var(--color-red-rgb), 0.1);
			}
		}

		&.focused {
			input {
				border-color: var(--color-blue-light);
			}
		}
	}
</style>
