import apiService from '../../services/api'
import helpers from '../../services/helpers'

export default {
	namespaced: true,
	state: {
		cartItems: [],
		checkoutResult: false
	},
	getters: {
		cartItems: state => state.cartItems,
		checkoutResult: state => state.checkoutResult,
	},
	mutations: {
		updateCartItems: (state, value) => {
			state.cartItems = value;
		},
		addCartItem: (state, value) => {
			let currentItem = state.cartItems.find(c => c.id === value.id && c.variant_id === value.variant_id);
			if (currentItem) {
				currentItem.quantity += value.quantity;
			} else {
				state.cartItems.push(value);
			}
		},
		removeCartItem: (state, value) => {
			let currentItems = state.cartItems.filter(c => c.variant_id !== value.variant_id);
			state.cartItems = currentItems;
		},
		updateCheckoutResult: (state, value) => {
			state.checkoutResult = value;
		},
	},
	actions: {
		checkout({state, rootGetters, commit}) {
			return new Promise((resolve, reject) => {
				let cartItems = state.cartItems.map(c => {
					return {
						id: c.id,
						variant_id: c.variant_id,
						quantity: c.quantity
					}
				})
				let orderItem = {
					billing_details: helpers.camelObjToUnderscore(rootGetters["personalInfo/personalDetails"]),
					billing_address: helpers.camelObjToUnderscore(rootGetters["personalInfo/billingDetails"]),
					products: cartItems
				}

				apiService.post('/api/orders', orderItem, false)
					.then(res => {
						commit('updateCheckoutResult', res);
						resolve(res);
					})
					.catch(err => {
						commit('updateCheckoutResult', false);
						reject(err);
					});
			})
		},

		checkPayment({commit}, uuid) {
			return apiService.post('/api/orders/check', {
				uuid: uuid
			});
		}
	}
}
