import Vue from 'vue'
import VueResource from 'vue-resource';
Vue.use(VueResource);

export default {

	post(url, request, auth = false) {
		let headers = auth ? this.getHeaders() : {}

		return Vue.http.post(url, request, { headers: headers })
			.then((response) => Promise.resolve(response.data))
			.catch((error) => {
				return Promise.reject(error.data);
			});
	},

	get(url, params, auth = false) {
		let config = {};
		if (auth) {
			config.headers = this.getHeaders()
		}

		if(params) {
			url += params;
		}

		return Vue.http.get(url, config)
			.then((response) => Promise.resolve(response.data))
			.catch((error) => {
				return Promise.reject(error.data);
			});
	},

	getHeaders() {
		let headers = {
			'Content-Type': 'application/json;charset=utf-8'
		};

		let token = JSON.parse(localStorage['ahWsUser']).user.token;
		// let token = user ? user : '';

		if(token !== '' && token !== undefined) {
			headers['Authorization'] = 'Bearer ' + token
		}

		return headers;
	}
}
