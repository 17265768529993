import VueRouter from "vue-router";
import store from "./store";

import Home from "./views/Home.vue";
import Product from "./views/Product.vue";
import Cart from "./views/cart/Cart.vue";
import Details from "./views/cart/Details.vue";
import Confirmation from "./views/cart/Confirmation.vue";
import PayfastForm from "./views/cart/PayfastForm.vue";
import PostPaymentCheck from "./views/cart/PostPaymentCheck.vue";
// import Success from "./views/Success.vue";
// import Failed from "./views/Failed.vue";
import PageNotFound from "./views/PageNotFound.vue";
import Login from "./views/auth/login.vue"
import Orders from "./views/auth/orders.vue"

const routes = [
	{ path: "/", 				name: "home", 				component: Home },
	{ path: "/product/:id", 	name: "product-page", 		component: Product },
	{ path: "/cart", 			name: "cart-page", 			component: Cart },
	{ path: "/details", 		name: "details-page", 		component: Details },
	{ path: "/confirmation", 	name: "confirmation-page", 	component: Confirmation },
	{ path: "/payfast", 		name: "payfast-page", 		component: PayfastForm },

	{ path: "/check/:uuid", 		name: "payment-check", 		component: PostPaymentCheck },

	{ path: "/login", 			name: "login", 				component: Login,            meta: {layout: 'auth'} },
	{ path: "/orders", 			name: "orders", 			component: Orders,           meta: {layout: 'auth'} },

	//fallback for 404s
	{ path: "*", component: PageNotFound }
];
const router = new VueRouter({
	routes,
	mode: 'history'
});

router.beforeEach((to, from, next) => {
	//pages that need a cart set
	let cartPages = [
		'details-page',
		'confirmation-page',
	];

	//if no cart items, go back home
	if (store.getters['cart/cartItems'].length < 1 && cartPages.includes(to.name)) {
		return next('/');
	}

	if (to.name === 'payfast-page' && !store.getters['cart/checkoutResult']) {
		return next('/');
	}

	return next();
})

router.afterEach((to, from) => {
	//clear navigation header
	store.commit('general/updateNavHeader', false);
})

export default router;
