<template>
	<div class="product-card flex-row flex-space-between"
		 :class="[
				{red: product.name.indexOf('Micro USB') > -1},
				{blue: product.name.indexOf('USB-C') > -1},
				{silver: product.name.indexOf('Lightning') > -1},
			 ]">
		<router-link :to="`/product/${product.id}`" class="product-image">
			<img :src="`/assets/images/products/${productImage}.png`">
		</router-link>
		<div class="product-details flex-column flex-space-between">
			<div class="flex-column flex-start">
				<router-link :to="`/product/${product.id}`"  class="product-name">
					<h1 :class="productPackaging" v-if="$route.name !== 'cart-page'" >{{product.name}}</h1>
					<h1 :class="productPackaging" v-else>{{ `${product.name} ${colour}` }}</h1>
				</router-link>
				<div class="product-description">
					{{product.description}}
				</div>
			</div>
			<div class="product-foot flex-space-between align-center">
				<div class="product-price">
					<span>R{{firstVariant.price | formatPrice}}</span> <span class="stock">in stock</span>
				</div>
				<div v-if="$route.name === 'cart-page'" class="flex-end justify-center align-center">
					<form-quantity :value="product.quantity" @input="updateQuantity"/>
					<icon class="remove" name="delete" @click.native.prevent="deleteItem"/>
				</div>
				<div v-else-if="$route.name === 'confirmation-page'" class="product-cta">
					<span class="quantity">{{ product.quantity }}</span>
				</div>
				<div v-else class="product-cta">
					<CTA class="small" :to="`/product/${product.id}`">View</CTA>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CTA from "./CTA";
import FormQuantity from "./FormQuantity";
import Icon from "./Icon";
export default {
	components: {Icon, FormQuantity, CTA},
	props: ['product'],
	computed: {
		productImage() {
			return this.$route.name === 'cart-page' || this.$route.name === 'confirmation-page'  ? this.currentVariant.sku : this.product.variants[0].sku;
		},
		productPackaging() {
			let packaging = '';
			if (this.productImage.indexOf('micro-usb') > -1) {
				packaging = 'red';
			} else if (this.productImage.indexOf('usb-c') > -1) {
				packaging = 'blue';
			} else if (this.productImage.indexOf('lightning') > -1) {
				packaging = 'silver';
			}
			return packaging;
		},
		firstVariant() {
			return this.product.variants[0]
		},
		currentVariant() {
			return this.product.variant_id ? this.product.variants.find(v => v.id === this.product.variant_id) : false;
		},
		colour() {
			//title case
			return this.currentVariant.variant_value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
		}
	},
	methods: {
		updateQuantity(value) {
			this.$store.commit('cart/addCartItem', {
				...this.product,
				quantity: value - this.product.quantity
			})
		},
		deleteItem() {
			this.$store.commit('cart/removeCartItem', {
				...this.product,
			})
		}
	},
	data() {
		return {

		}
	},
}
</script>
<style lang="scss">
.product-card {
	background: #FFFFFF;
	box-shadow: 0 10px 40px 0 rgba(190,197,210,0.30);
	border-radius: 10px;
	padding: 2rem;
	margin-bottom: 1rem;
	overflow: hidden;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		height: 100%;
		border-left: 0.5rem solid;
	}
	&.red::before {
		border-left-color: var(--color-package-red);
	}
	&.blue::before {
		border-left-color: var(--color-package-blue);
	}
	&.silver::before {
		border-left-color: var(--color-package-silver);
	}

	.product-image {
		display: flex;
		max-width: 11rem;

		img {
			align-self: center;
			border-bottom: 1px solid var(--color-grey-light);
			max-height: 100%;
			max-width: 100%;
		}
	}
	.product-name {
		margin-bottom: 1rem;
		color: var(--color-grey-dark);

		//h1 {
		//	display: inline-block;
		//	font-size: 2rem;
		//	line-height: 2.4rem;
		//	padding: 0.8rem 1.5rem;
		//	border-radius: 10rem;
		//
		//	&.blue {
		//		background-color: var(--color-package-blue);
		//		color: #fff;
		//	}
		//	&.red {
		//		background-color: var(--color-package-red);
		//		color: #fff;
		//	}
		//	&.silver {
		//		background-color: var(--color-package-silver);
		//		color: #fff;
		//	}
		//}
	}
	.product-details {
		width: 100%;
		margin-left: 1.5rem;
		.product-description {
			font-size: 1.2rem;
			line-height: 1.8rem;
			color: var(--color-grey-medium);
			font-weight: var(--font-weight-bold);
		}
	}
	.product-foot {
		margin-top: 1rem
	}

	.product-price, .quantity {
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: var(--color-blue-dark);
		font-weight: var(--font-weight-bold);
		margin-right: 1rem;
	}

	.stock {
		//font-size: 1.4rem;
		font-weight: var(--font-weight-book);
		font-style: italic;
		color: var(--color-grey-medium);
	}

	.quantity {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--color-off-white);
		border-radius: 10rem;
		width: 2.8rem;
		height: 2.8rem;
	}

	.icon.remove {
		width: 1.7rem;
		height: 2.2rem;
		fill: var(--color-grey-medium);
		cursor: pointer;
		margin-left: 2rem
	}
}
</style>
