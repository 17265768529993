<template>
	<div class="cart-page">
		<div class="products">
			<product-card v-for="product in products" :key="product.variant_id" :product="product"></product-card>
			<h2 class="no-items" v-if="products.length === 0">You have no items in your cart :(</h2>
		</div>
		<cart-footer v-if="products.length > 0" cta-label="Checkout" @click="checkout"/>
	</div>
</template>
<script>
import ProductCard from "@/components/ProductCard";
import { mapGetters } from 'vuex'
import CTA from "../../components/CTA";
import CartFooter from "../../components/CartFooter";

export default {
	components: {CartFooter, CTA, ProductCard},
	computed: {
		...mapGetters({
			products: 'cart/cartItems',
		}),
	},
	methods: {
		checkout() {
			this.$router.push('/details')
		}
	},
	mounted() {
		this.$store.commit('general/updateNavHeader', 'Cart');
		if (!this.products || this.products.length === 0) {
			this.$store.dispatch('products/getProducts');
		}
	}
}
</script>
<style lang="scss">
.cart-page {
	padding-bottom: 10rem;
	.products {
		padding: 2.5rem 2rem;

		.no-items {
			text-align: center;
			font-size: 2.2rem;
			line-height: 2.6rem;
			font-weight: var(--font-weight-black);
		}
	}
}
</style>
