<template>
	<router-link class="button" v-if="to" :to="to" @click="clicked">
		<slot></slot>
	</router-link>
	<a class="button" v-else-if="href" :href="href" @click="clicked">
		<slot></slot>
	</a>
	<button class="button" v-else @click="clicked">
		<slot></slot>
	</button>
</template>
<script>
export default {
	props: ['to', 'href'],
	methods: {
		clicked(e) {
			this.$emit('click', e);
		}
	},
}
</script>
<style lang="scss">
.button {
	background-color: var(--color-blue-light);
	border: none;
	border-radius: 100rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 1.4rem;
	padding: 1.7rem;
	font-weight: var(--font-weight-bold);
	color: #ffffff;
	min-width: 21.5rem;

	&.medium {
		min-width: 15.5rem;
 	}

	&.small, &.medium {
		min-width: 13.3rem;
		font-size: 1.2rem;
		line-height: 1.5rem;
		padding: 10px;
 	}

	&.discrete {
		background-color: transparent;
		padding: 0;
		color: var(--color-grey-medium);
	}
}
</style>
