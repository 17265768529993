import apiService from '../../services/api'

export default {
	namespaced: true,
	state: {
		products: [],
	},
	getters: {
		products: state => state.products
	},
	mutations: {
		updateProducts: (state, value) => {
			state.products = value;
		}
	},
	actions: {
		getProducts: ({commit}, _payload) => {
			return new Promise((resolve, reject) => {
				apiService.get('/api/products')
					.then(res => {
						commit('updateProducts', res);
						resolve(res);
					})
					.catch(err => {
						reject(err.body);
					})
			})
		}
	}
}
