<template>
	<form class="details-page">
		<div class="personal-details">
			<h1>Personal details</h1>
			<div class="fields flex-column">
				<FormInput v-for="field  in personalDetailsFields"
						   :key="field.name"
						   :name="field.name"
						   :label="field.label"
						   :type="field.type"
						   :pattern="field.pattern"
						   :title="field.title"
						   v-model="personalDetails[field.name]"
						   :ref="field.name"
						   :required="field.required"/>
			</div>
		</div>
		<div class="billing-details">
			<h1>Billing address</h1>
			<div class="fields flex-column">
				<FormInput v-for="field  in billingDetailsFields"
						   :key="field.name"
						   :name="field.name"
						   :label="field.label"
						   :type="field.type"
						   :pattern="field.pattern"
						   :title="field.title"
						   :ref="field.name"
						   v-model="billingAddress[field.name]"
						   :required="field.required"/>
			</div>
		</div>
		<div class="error" v-if="error">{{error.message}}</div>

<!--		<div class="terms-card">-->
<!--			<h3>Terms and Conditions</h3>-->
<!--			<p>All Information on this site is Private, Confidential and Proprietary. Do not share with external parties. Copyright © Afrihost-->
<!--				{{ new Date().getFullYear() }}.</p>-->
<!--		</div>-->

		<cart-footer @click.stop.prevent="next" cta-label="Next"/>
	</form>
</template>
<script>
import {mapGetters} from "vuex";
import FormInput from "../../components/FormInput";
import CartFooter from "../../components/CartFooter";

export default {
	components: {CartFooter, FormInput},
	computed: {
		personalDetailsFields() {
			return [
				{
					name: "firstName",
					label: "First name",
					required: true,
					type: 'text'
				},
				{
					name: "lastName",
					label: "Last name",
					required: true,
					type: 'text'
				},
				{
					name: "email",
					label: "Email address",
					required: true,
					type: 'email'
				},
				{
					name: "contactNumber",
					label: "Cell number",
					required: true,
					type: 'tel'
				},

			]
		},
		billingDetailsFields() {
			return [
				{
					name: "address1",
					label: "Address line 1",
					required: true,
					pattern: ".{4,}",
					title: "Minimum 3 characters",
					type: 'text'
				},
				{
					name: "address2",
					label: "Address line 2",
					required: false,
					type: 'text'
				},
				{
					name: "suburb",
					label: "Suburb",
					required: true,
					pattern: ".{4,}",
					title: "Minimum 3 characters",
					type: 'text'
				},
				{
					name: "city",
					label: "City",
					required: true,
					pattern: ".{4,}",
					title: "Minimum 3 characters",
					type: 'text'
				},
				{
					name: "province",
					label: "Province",
					required: true,
					pattern: ".{4,}",
					title: "Minimum 3 characters",
					type: 'text'
				},
				{
					name: "postalCode",
					label: "Postal code",
					required: true,
					pattern: ".{4,}",
					title: "Minimum 3 characters",
					type: 'text'
				},

			]
		},
	},
	data() {
		return {
			personalDetails: false,
			billingAddress: false,
			error: false,
		}
	},
	methods: {
		next() {
			console.log(this.$refs);
			var errors = 0;
			for (var prop in this.$refs) {
				if (Object.prototype.hasOwnProperty.call(this.$refs, prop)) {
					if (this.$refs[prop][0].$el.querySelector('input').validationMessage) {
						errors++;
					}
				}
			}

			if (this.personalDetails.contactNumber.length > 10) {
				this.error = { message: 'Please enter a valid cell number.'};
				return;
			} else if (errors > 0) {
				this.error = { message: 'Error: Please make sure that the information above is valid.'};
				return;
			}

				//save details to store
			this.$store.commit('personalInfo/updatePersonalDetails', this.personalDetails);
			this.$store.commit('personalInfo/updateBillingDetails', this.billingAddress);

			this.$router.push('/confirmation');
		}
	},
	mounted() {
		this.personalDetails = {
			...this.$store.getters['personalInfo/personalDetails']
		};
		this.billingAddress = {
			...this.$store.getters['personalInfo/billingDetails']
		};
	}
}
</script>
<style lang="scss">
@import "../../../scss/setup/mixins";
.details-page {
	padding: 4rem 3rem 10rem;
	h1 {
		padding: 0 2rem;
		margin-bottom: 2rem;
	}
	.fields {
		margin-bottom: 4rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
	}
	.personal-details {
		.fields {
			& > * {
				flex-basis: 50%;
				padding-bottom: 1rem;
				&:nth-child(odd) {
					padding-right: 1rem;
				}
			}
		}
	}
	.billing-details {
		.fields {
			& > * {
				flex-basis: 100%;
				padding-bottom: 1rem;
			}
		}
	}

	.terms-card {
		margin-bottom: 4rem;
		background-color: rgba(#000, 0.2);
		padding: 1.4rem 1.2rem;
		color: #FFFFFF;
		font-size: 1.2rem;
		line-height: 1.8rem;
		border-radius: 1rem;

		h3 {
			font-weight: var(--font-weight-bold);
		}
	}

	@include break-point(mobile) {
		.fields > .form-input {
			flex-basis: 100%;
			margin: 0;
			padding-right: 0 !important;
		}
	}
}
</style>
