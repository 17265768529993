<template>
	<form id="payfast-form" method="POST" :action="form.url" v-if="form.form">
		<input type="hidden"
			   v-for="(value, name) in form.form"
			   :name="name"
			   :key="name"
			   :value="value">
	</form>
</template>
<script>
export default {
	data() {
		return {
			form: {}
		}
	},
	mounted() {
		this.form = {
			...this.$store.getters['cart/checkoutResult']
		}

		if (this.form.form === undefined) {
			this.$router.push('/');
			return;
		}

		this.$nextTick(() => {
			document.querySelector('#payfast-form').submit()
		})
	}
}
</script>
<style>
#payfast-form {
	display: none;
}
</style>
