<template>
	<div class="order-success">
		<div class="center">
			<img src="/assets/images/global/order-success.png" alt="success" srcset="/assets/images/global/order-success.png 1x, /assets/images/global/order-success@2x.png 2x">
			<h1>Order {{ orderNumber }} Complete</h1>
			<p>Your order has been processed.<br/>
				You may collect your items from an agent.
			</p>
			<CTA to="/">Done</CTA>
		</div>
	</div>
</template>
<script>
import CTA from "./CTA";
export default {
	components: {CTA},
	props: ['orderNumber']
}
</script>
<style lang="scss">
.order-success {
	text-align: center;
	height: calc(100vh - 8rem);
	position: relative;

	.center {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: 32rem;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}

	img {
		margin-bottom: 4rem;
	}
	h1 {
		font-size: 3.6rem;
		line-height: 4.4rem;
		margin-bottom: 2rem;
	}
	p {
		font-size: 1.4rem;
		line-height: 2.1rem;
		font-weight: var(--font-weight-bold);
		color: var(--color-grey-medium);
		margin-bottom: 2rem;
	}
}
</style>
