<template>
	
</template>
<script>
export default {
	mounted() {
		this.$router.push('/');
	}
}
</script>
