<template>
	<div>
		<div class="products">
			<product-card v-for="product in products" :key="product.id" :product="product"></product-card>
		</div>
	</div>
</template>
<script>
import ProductCard from "@/components/ProductCard";
import { mapGetters } from 'vuex'

export default {
	components: {ProductCard},
	computed: {
		...mapGetters({
			products: 'products/products',
		}),
	},
	mounted() {
		if (!this.products || this.products.length === 0) {
			this.$store.dispatch('products/getProducts');
		}
	}
}
</script>
<style lang="scss">
.products {
	padding: 2.5rem 2rem;
}
</style>
