<template>
	<div v-if="!loading">
		<payment-failed v-if="!paymentSuccess" />
		<payment-success v-if="paymentSuccess" :order-number="orderNumber" />
	</div>
</template>

<script>
	import PaymentFailed from "../../components/PaymentFailed";
	import PaymentSuccess from "../../components/PaymentSuccess";

	export default {
		components: {
			PaymentFailed,
			PaymentSuccess
		},
		computed: {
			loading() {
				return this.$store.getters['general/loading']
			}
		},
		methods: {
			async checkPayment() {
				await this.$store.dispatch('cart/checkPayment', this.$route.params.uuid).then(r => {
					this.paymentSuccess = true;
					this.orderNumber = r.order_number

					this.$store.dispatch('general/scrubStore');

				}, e => {
					this.paymentSuccess = false;
				})

				// hide loader
				this.$store.commit('general/setLoading', false);
			}
		},
		data() {
			return {
				paymentSuccess: false,
				orderNumber: false
			}
		},
		mounted() {
			this.$store.commit('general/setLoading', true);
			this.checkPayment();
		}
	}
</script>
