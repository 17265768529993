<template>
	<div id="loader" v-if="showLoader">
		<span></span>
		<span></span>
		<span></span>
	</div>
</template>
<script>
export default {
	computed: {
		showLoader() {
			// return true;
			return this.$store.getters['general/loading'];
		}
	}
}
</script>
<style lang="scss">
#loader {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(#ffffff, 0.8);
	z-index: 1000;

	display: flex;
	justify-content: center;
	align-items: center;

	span {
		margin: 0 0.5rem;
		width: 1rem;
		height: 1rem;
		background-color: var(--color-blue-light);
		border-radius: 10rem;

		animation: tap 2s both ease-in-out infinite;

		&:nth-child(1) {
			animation-delay: 0s;
		}
		&:nth-child(2) {
			animation-delay: 0.15s;
		}
		&:nth-child(3) {
			animation-delay: 0.25s;
		}
	}

}
</style>
