import apiService from '../../services/api'

export default {
	namespaced: true,
	state: {
		navHeader: false,
		loading: false,
	},
	getters: {
		navHeader: state => state.navHeader,
		loading: state => state.loading,
	},
	mutations: {
		updateNavHeader: (state, value) => {
			state.navHeader = value
		},
		setLoading: (state, value) => {
			state.loading = value
		},
	},
	actions: {
		scrubStore({commit}) {
			// clear the users cart
			commit('cart/updateCartItems', [], { root: true })

			// clear the user's billing details
			commit('personalInfo/updateBillingDetails', {
				address1: null,
				address2: null,
				suburb: null,
				city: null,
				province: null,
				postalCode: null,
			}, { root: true });

			// clear the user's personal details
			commit('personalInfo/updatePersonalDetails', {
				firstName: null,
				lastName: null,
				email: null,
				contactNumber: null,
			}, { root: true });
		},
	}
}
