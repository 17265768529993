import apiService from '../../services/api'

export default {
	namespaced: true,
	state: {
		orders: false
	},
	getters: {
		orders: state => state.orders
	},
	mutations: {
		updateOrders: (state, value) => {
			state.orders = value;
		},
	},
	actions: {
		fetchOrders: ({commit}, _payload) => {
			return new Promise((resolve, reject) => {
				apiService.get('/api/internal/orders', _payload, true)
					.then((response) => {
						commit('updateOrders', response);
						resolve(true)
					})
					.catch((err) => {
						reject(err[0])
					})
			})
		},
		fetchOrder: ({commit}, _payload) => {
			return new Promise((resolve, reject) => {
				apiService.get(`/api/internal/orders/${_payload}`, null, true)
					.then((response) => {
						resolve(response)
					})
					.catch((err) => {
						reject(err[0])
					})
			})
		},
		searchOrders: ({commit}, _payload) => {
			return new Promise((resolve, reject) => {
				apiService.post(`/api/internal/orders/search`, {
					query: _payload.query
				}, true)
					.then((response) => {
						resolve(response)
					})
					.catch((err) => {
						reject(err[0])
					})
			})
		}
	}
}
