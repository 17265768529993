import Vue from 'vue'
import VueRouter from 'vue-router'
import router from "./router";
import store from "./store/index";
import AppLayout from "./layouts/AppLayout";
import Vidle from 'v-idle'

Vue.use(Vidle)
Vue.use(VueRouter)

window.WalkinApp = new Vue({
	el: '#app',
	router,
	store,
	render: h => h(AppLayout)
});

/*** Global Filters ***/
Vue.filter('formatPrice', (val) => {
	return val.toFixed(2)
})
/*** END Global Filters ***/
