import apiService from '../../services/api'

export default {
	namespaced: true,
	state: {
		email: '',
		token: false
	},
	getters: {
		token: state => state.token,
		email: state => state.email
	},
	mutations: {
		updateToken: (state, value) => {
			state.token = value;
		},
		updateEmail: (state, value) => {
			state.email = value;
		},
	},
	actions: {
		login: ({commit}, _payload) => {
			return new Promise((resolve, reject) => {
				apiService.post('/api/internal/auth/login', _payload, true)
					.then((response) => {
						commit('updateToken', response.token);
						commit('updateEmail', _payload.email);
						resolve(true)
					})
					.catch((err) => {
						if (err[0].field === 'password' || err[0].field === 'email') {
							reject({
								message: 'Incorrect login details supplied'
							})
						} else {
							reject(err[0])
						}
					})
			})
		},
		logout: ({commit}) => {
			commit('updateToken', false);
			commit('updateEmail', '');
		}
	}
}
