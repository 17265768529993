<template>
	<div id="login">
		<div class="logo">
			<img src="/assets/images/global/logo-afrihost.png" srcset="/assets/images/global/logo-afrihost.png 1x, /assets/images/global/logo-afrihost@2x.png 2x" alt="Afrihost"/>
		</div>
		<div class="form" ref="form" @keypress.enter="login">
			<FormInput type="email" label="Email Address" v-model="form.email" required="true" autocomplete="email"/>
			<FormInput type="password" label="Password" v-model="form.password" required="true" autocomplete="password"/>
		</div>
		<div class="flex-center">
			<CTA class="primary" @click="login">Log in</CTA>
		</div>
		<div class="error" v-if="error">{{error.message}}</div>
	</div>
</template>
<script>
import ProductCard from "@/components/ProductCard";
import { mapGetters } from 'vuex'
import FormInput from "../../components/FormInput";
import CTA from "../../components/CTA";

export default {
	components: {CTA, FormInput, ProductCard},
	layout: 'auth',
	methods: {
		login() {
			this.error = false;

			if (this.form.email.match(/^[\w\d._%+-]+@([\w\d.-]+\.)+[\w-]{2,}$/)) {
				this.$store.dispatch('user/login', {
					...this.form
				})
					.then((result) => {
						if (result) {
							this.next();
						}
					})
					.catch((err) => {
						this.error = err;
					})
			} else {
				this.shake();
				this.error = {message: 'Please enter a valid email'}
			}
		},
		shake() {
			this.$refs['form'].classList.add('shake')
		},
		next() {
			this.$router.push('/orders')
		}
	},
	computed: {
		...mapGetters({
			products: 'products/products',
		}),
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			error: false
		}
	},
	mounted() {
		if (this.$store.getters['user/token']) {
			this.next()
		}
	}
}
</script>
<style lang="scss">
#login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	height: 100vh;
	width: 100vw;
	padding: 4rem;

	background-color: var(--color-blue-dark);

	& > * {
		width: 100%;
		max-width: 48rem;
		margin: 0 auto;
	}

	.logo {
		display: flex;
		justify-content: center;
		align-content: center;
		width: 100%;
		margin-bottom: 4rem;
	}

	.form {
		margin-bottom: 4rem;
		.form-input {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
			input {
				background-color: #FFFFFF;
			}
		}
	}

	.error {
		margin-bottom: -4rem;
		margin-top: 2.4rem;
	}
}

.shake {
	animation: shake 0.5s;
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	20% {
		transform: translateX(1rem);
	}
	40% {
		transform: translateX(-1rem);
	}
	60% {
		transform: translateX(1rem);
	}
	80% {
		transform: translateX(-1rem);
	}
	100% {
		transform: translateX(0);
	}
}
</style>
