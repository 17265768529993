<template>
	<div class="confirmation-page">
		<div class="billing-details">
			<h1>Billing Details</h1>
			<div class="personals flex-row">
				<div class="flex-column">
					<span>{{ `${details.firstName} ${details.lastName}` }}</span>
					<span>{{ details.email }}</span>
					<span>{{ details.contactNumber }}</span>
				</div>
				<div class="flex-column">
					<span>{{ details.address1 }}</span>
					<span>{{ details.address2 }}</span>
					<span>{{ details.suburb }}</span>
					<span>{{ details.city }}</span>
					<span>{{ details.province }}</span>
					<span>{{ details.postalCode }}</span>
				</div>
			</div>
		</div>
		<div class="cart">
			<product-card v-for="product in products" :key="product.variant_id" :product="product"></product-card>
		</div>
		<div class="error" v-if="error">{{error.message}}</div>
		<cart-footer cta-label="Make payment" @click="next"></cart-footer>
	</div>
</template>
<script>
import ProductCard from "../../components/ProductCard";
import CartFooter from "../../components/CartFooter";
export default {
	components: {CartFooter, ProductCard},
	computed: {
		details() {
			return {
				...this.$store.getters['personalInfo/billingDetails'],
				...this.$store.getters['personalInfo/personalDetails']
			}
		},
		products() {
			return this.$store.getters['cart/cartItems'];
		},
	},
	data() {
		return {
			error: false
		}
	},
	methods: {
		next() {
			this.$store.commit('general/setLoading', true);
			this.$store.dispatch('cart/checkout')
			.then(res => {
				console.log(res);
				this.$router.push('/payfast')
			}).catch(err => {
				console.log(err);
				this.$store.commit('general/setLoading', false);
				//show global error "Something went wrong"

				if (err.errors) {
					switch (err.errors[0].field) {
						case "billing_address.suburb":
							this.error = {message: "Please enter a valid suburb"}
							break;
						default:
							this.error = {message: "Something went wrong, please review your details and try again."}
							break;
					}
				}

			});
		},
	},
	mounted() {
		this.$store.commit('general/updateNavHeader', 'Confirm your order');
	}
}
</script>
<style lang="scss">
.confirmation-page {
	padding-bottom: 10rem;

	.billing-details {
		padding: 2rem 3rem;
		background: #FFFFFF;
		box-shadow: 0 0 1.5rem 0 rgba(104,104,104,0.15);
		border-radius: 1rem;
		margin: 2rem 3rem 2rem;

		h1 {
			margin-bottom: 2rem;
		}

		.personals {
			& > * {
				font-size: 1.4rem;
				line-height: 2.1rem;
				font-weight: var(--font-weight-bold);
				color: var(--color-grey-medium);
				flex-basis: 50%;
			}
		}
	}

	.cart {
		margin: 2rem 3rem 2rem;
	}
}
</style>
